import React, { FC } from "react";
import "../assets/css/styles.css";
import { Center } from "./center";
import { graphql, Link, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { LayoutQuery, Maybe, Wp_MenuItem } from "../../graphql-types";

const Layout: FC = props => {
  const { children } = props;
  const data = useStaticQuery<LayoutQuery>(graphql`
    query Layout {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 300, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }

      wp {
        generalSettings {
          url
        }

        menus {
          edges {
            node {
              slug
              menuItems {
                edges {
                  node {
                    id
                    label
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menuItems = data.wp.menus?.edges?.find(
    edge => edge?.node?.slug === "primaire-navigatie"
  )?.node?.menuItems as { __typename?: "Wp_MenuToMenuItemConnection" } & {
    edges?: Maybe<
      Array<
        Maybe<
          { __typename?: "Wp_MenuToMenuItemConnectionEdge" } & {
            node?: Maybe<
              { __typename?: "Wp_MenuItem" } & Pick<
                Wp_MenuItem,
                "id" | "label" | "url"
              >
            >;
          }
        >
      >
    >;
  };

  return (
    <>
      <header className="relative z-50">
        <Center>
          <div className="flex justify-between items-center">
            <div className="w-56 transform translate-y-4 -mb-4">
              <Link to="/">
                <Img fluid={data.logo?.childImageSharp?.fluid as FluidObject} />
              </Link>
            </div>
            <nav>
              <ul className="flex flex-wrap">
                {menuItems.edges?.map((edge, i) => (
                  <li key={edge?.node?.id} className="px-4 py-2">
                    <Link
                      className="pb-2 transition duration-150 ease-in-out border-b border-coral border-opacity-0"
                      activeClassName="border-opacity-100"
                      to={
                        edge?.node?.url?.replace(
                          data.wp.generalSettings?.url as string,
                          ""
                        ) as string
                      }
                    >
                      {edge?.node?.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </Center>
      </header>

      <main>{children}</main>

      <footer className="py-10 text-right">
        <Center>
          website:{" "}
          <a href="https://www.webba.nl/" target="_blank" className="underline">
            Webba
          </a>
        </Center>
      </footer>
    </>
  );
};

export default Layout;
