import React, { FC } from "react";

export const Center: FC = props => {
  const { children } = props;

  return (
    <div
      className="mx-auto box-content px-5 md:px-16 lg:px-20"
      style={{ maxWidth: "70rem" }}
    >
      {children}
    </div>
  );
};
